/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { createRoutesFromElements, Route } from 'react-router-dom';

import LayoutMain from 'src/presentations/components/templates/Main';
import LayoutRoot from 'src/presentations/components/templates/Root';
import LoginPage from 'src/presentations/components/pages/loginPage';
import Analytics from 'src/presentations/components/pages/analytics';
import LandingPage from 'src/presentations/components/pages/landing';
import { PerformanceAnalysis } from 'src/presentations/components/pages/performance/PerformanceAnalysis';

import NotFound from 'src/containers/NotFound';
import UnderConstructionPage from 'src/presentations/components/pages/underConstruction/UnderConstructionPage';
import RequireAuth from './RequireAuth';

const Dashboard = lazy(() => import('src/containers/Dashboard'));
const InternalDashboardPage = lazy(() => import('src/presentations/components/pages/internalDashboard/InternalDashboardPage'));
const ActionDetailsPage = lazy(() => import('src/presentations/components/pages/actionDetails/ActionDetails'));
const ContractManager = lazy(() => import('src/containers/contractManager/ContractManager'));

export const routesConfig = createRoutesFromElements(
  <>
    <Route path='/login' element={<LoginPage />} />

    <Route element={<LayoutRoot />}>
      <Route
        path='/'
        element={
          <RequireAuth>
            <LandingPage />
          </RequireAuth>
        }
      />
      <Route
        path='/analytics'
        element={
          <RequireAuth>
            <Analytics />
          </RequireAuth>
        }
      />
      <Route
        element={
          <RequireAuth>
            <LayoutMain />
          </RequireAuth>
        }
      >
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/internal-dashboard' element={<InternalDashboardPage />} />
        <Route path='/action-details/:id' element={<ActionDetailsPage />} />
        <Route path='/performance' element={<PerformanceAnalysis />} />
        <Route path='/ratecard-manager' element={<ContractManager />} />
        <Route path='/under-construction' element={<UnderConstructionPage />} />
      </Route>
    </Route>
    <Route path='*' element={<NotFound />} />
  </>
);
