import { IRootState } from '../../store';
import IUser, { COGNITO_GROUP } from './user';

const authSelectors = {
  selectUser: (state: IRootState): IUser | null => state.auth.user,
  selectIsLoading: (state: IRootState): boolean => state.auth.isLoading,
  selectUserRole: (state: IRootState): COGNITO_GROUP | null => {
    const roles = state.auth.user?.roles || [];
    if (roles.includes(COGNITO_GROUP.OPERATIONS_MANAGER)) {
      return COGNITO_GROUP.OPERATIONS_MANAGER;
    }
    if (roles.includes(COGNITO_GROUP.PROCUREMENT_MANAGER)) {
      return COGNITO_GROUP.PROCUREMENT_MANAGER;
    }
    return roles[0] || null; // Fallback to the first role if none matched
  },
  getIsRoleFinance: (state: IRootState): boolean => state.auth.user?.roles?.includes(COGNITO_GROUP.FINANCE),
  getIsRoleOperations: (state: IRootState): boolean => state.auth.user?.roles?.includes(COGNITO_GROUP.OPERATIONS_MANAGER),
  getIsRoleProcurement: (state: IRootState): boolean => state.auth.user?.roles?.includes(COGNITO_GROUP.PROCUREMENT_MANAGER),
};

export default authSelectors;
