/**
 * Template Root
 */
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PageError from 'src/containers/PageError';
import { UploadInvoice } from '../molecules/uploadInvoice/Upload';
import { useNoRateCardSelector } from '../organisms/actionDetails/noRateCard/Selector';
import { NoRateCardModal } from '../organisms/actionDetails/noRateCard/NoRateCardModal';
import { ToastResult } from '../molecules/toast/ToastResult';
import { ModalContact } from '../molecules/contact/ModalContact';

const TemplateRoot: React.FC = () => {
  const { isShowModal, selected } = useNoRateCardSelector();

  return (
    <ErrorBoundary fallback={<PageError />}>
      <Outlet />
      <UploadInvoice />
      <ToastResult />
      <ModalContact />
      {isShowModal && selected && <NoRateCardModal open={isShowModal} invoice={selected} />}
    </ErrorBoundary>
  );
};

export default TemplateRoot;
