import { useCallback } from 'react';
import clsx from 'clsx';

import { useGetNotifications, useMarkNotificationAsRead } from 'src/use-cases/notification/useNotification';
import IconNotification from '../../atom/icons/Notification';
import { NotificationContent } from './Content';
import { useNotificationSelector } from './NotificationSelector';
import { NotificationDetail } from './Detail';
import { useGetDomainMetaData } from '../../../../use-cases/invoice/useInvoices';

export const Notification = () => {
  const { setIsShowContent, isShowContent } = useNotificationSelector();

  const { mutate } = useMarkNotificationAsRead();

  const { data: domainMetaData } = useGetDomainMetaData();

  const { data } = useGetNotifications();
  const notifications = data?.notifications || [];

  const onClickNotifIcon = useCallback(() => {
    if (!isShowContent) {
      mutate();
    }
    setIsShowContent(!isShowContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowContent, setIsShowContent]);

  return (
    <>
      <div className='relative flex items-center justify-center mr-20'>
        <button
          id='btn-trigger-notif'
          type='button'
          onClick={onClickNotifIcon}
          className={clsx('w-[40px] h-[40px] rounded-full flex items-center justify-center hover:bg-[#171D21]', isShowContent && 'bg-[#171D21]')}
        >
          <IconNotification />
          {data && !data?.isRead && <div className='w-12 h-12 rounded-full border-2 border-[#171D21] bg-red-40 absolute right-8 top-6' />}
        </button>
      </div>

      <NotificationContent allNotifications={notifications} />
      <NotificationDetail errorInvoiceFileProcessingStatuses={domainMetaData?.errorProcessingStatuses} />
    </>
  );
};
