import { FC, ReactNode, useEffect, useState } from 'react';
import { useMaterialReactTable, type MRT_ColumnDef, MRT_RowSelectionState, MRT_Row } from 'material-react-table';
import { Box, IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import IconEdit from 'src/assets/images/icons/edit-2.svg';
import { formatDate } from 'src/utils/date';
import { RhfMuiDateRangePicker } from 'src/presentations/components/molecules/fields/rhf-mui-fields';
import { nameofFactory } from 'src/utils/nameofFactory';
import { NoContractForm, NoContractStep, RejectEnum, SelectedShippingServiceItem } from './types';

interface ITableProps {
  name: string;
  data: SelectedShippingServiceItem[];
  onChangeSelected?: () => void;
  isEditable?: boolean;
  isSelectable?: boolean;
  hideMismatched?: boolean;
  activeStep: NoContractStep;
}

export interface ITableCellProps {
  renderedCellValue: ReactNode;
  children?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  fieldName?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  row?: MRT_Row<SelectedShippingServiceItem>;
  isMismatched?: boolean;
}

export interface IColumnsProps {
  isEditable: boolean;
  rowIsEditable: boolean;
  hideMismatched?: boolean;
  onEdit(status: boolean): void;
}

const nameofForm = nameofFactory<NoContractForm>();

const TableCell = ({ renderedCellValue, isMismatched, children }: ITableCellProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      color: isMismatched ? 'red' : '',
    }}
  >
    {children || renderedCellValue}
  </Box>
);

const useCompareColumns = ({ isEditable, rowIsEditable, onEdit, hideMismatched }: IColumnsProps): MRT_ColumnDef<SelectedShippingServiceItem>[] => [
  {
    enableEditing: false,
    accessorKey: 'polpod',
    header: 'POL/POD',
    accessorFn: (row) => <>{row?.existingShippingService?.listZone?.map((item) => `${item?.pol} / ${item?.pod}`)?.join(', ')}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell
        renderedCellValue={renderedCellValue}
        isMismatched={!hideMismatched && ['pol', 'pod'].some((val) => row?.original?.mismatchedFields?.includes(val))}
      />
    ),
  },
  {
    enableEditing: false,
    accessorKey: 'shipperName',
    header: 'SHIPPER NAME',
    accessorFn: (row) => <>{row?.existingShippingService?.shipperName}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell renderedCellValue={renderedCellValue} isMismatched={!hideMismatched && row?.original?.mismatchedFields?.includes('shipper name')} />
    ),
  },
  {
    enableEditing: false,
    accessorKey: 'consigneeName',
    header: 'CONSIGNEE NAME',
    accessorFn: (row) => <>{row?.existingShippingService?.consigneeName}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell renderedCellValue={renderedCellValue} isMismatched={!hideMismatched && row?.original?.mismatchedFields?.includes('consignee name')} />
    ),
  },
  {
    enableEditing: false,
    accessorKey: 'modality',
    header: 'MODALITY',
    accessorFn: (row) => <>{row?.existingShippingService?.modality}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell renderedCellValue={renderedCellValue} isMismatched={!hideMismatched && row?.original?.mismatchedFields?.includes('modality')} />
    ),
  },
  {
    header: 'SERVICE',
    enableEditing: false,
    accessorKey: 'service',
    accessorFn: (row) => <>{row?.existingShippingService?.service}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell renderedCellValue={renderedCellValue} isMismatched={!hideMismatched && row?.original?.mismatchedFields?.includes('service')} />
    ),
  },
  {
    header: 'TYPE',
    enableEditing: false,
    accessorKey: 'type',
    accessorFn: (row) => <>{row?.existingShippingService?.type}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell renderedCellValue={renderedCellValue} isMismatched={!hideMismatched && row?.original?.mismatchedFields?.includes('type')} />
    ),
  },
  {
    header: 'CARRIER',
    enableEditing: false,
    accessorKey: 'carrier',
    accessorFn: (row) => <>{row?.existingShippingService?.carrier}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell renderedCellValue={renderedCellValue} isMismatched={!hideMismatched && row?.original?.mismatchedFields?.includes('carrier')} />
    ),
  },
  {
    header: 'INCOTERM',
    enableEditing: false,
    accessorKey: 'incoterm',
    accessorFn: (row) => <>{row?.existingShippingService?.incoterm}</>,
    Cell: ({ renderedCellValue, row }) => (
      <TableCell renderedCellValue={renderedCellValue} isMismatched={!hideMismatched && row?.original?.mismatchedFields?.includes('incoterm')} />
    ),
  },
  {
    header: 'VALIDITY PERIOD',
    accessorKey: 'validityPeriod',
    enableEditing: rowIsEditable,
    accessorFn: (row) => (
      <>
        {formatDate(row.existingShippingService?.validityPeriod?.start)} to {formatDate(row.existingShippingService?.validityPeriod?.end)}
      </>
    ),
    Cell: ({ renderedCellValue, row }) => (
      <TableCell
        renderedCellValue={renderedCellValue}
        row={row}
        fieldName='validityPeriod'
        isMismatched={row?.original?.mismatchedFields?.includes('validity period')}
      >
        {renderedCellValue}
        {isEditable && (
          <IconButton
            onClick={() => {
              onEdit(true);
            }}
          >
            <img src={IconEdit} alt='' className='w-24' />
          </IconButton>
        )}
      </TableCell>
    ),
    Edit: () => <RhfMuiDateRangePicker size='small' name={`${nameofForm('newMatchingService')}.existingShippingService.validityPeriod`} />,
  },
];

export const TableShippingService: FC<ITableProps> = (props) => {
  const { data = [], onChangeSelected, isEditable, isSelectable, activeStep, name, hideMismatched } = props;

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { setValue, watch } = useFormContext<NoContractForm>();
  const [rowIsEditable, setRowIsEditable] = useState(false);
  const columns = useCompareColumns({
    isEditable,
    rowIsEditable,
    hideMismatched,
    onEdit: (status: boolean) => setRowIsEditable(status),
  });
  const { contractChoice, verdict, agreementType } = watch();

  useEffect(() => {
    const selectedValue = Object.keys(rowSelection)[0];
    if (selectedValue) {
      setValue('contractChoice', selectedValue);
      if (onChangeSelected) {
        onChangeSelected();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  useEffect(() => {
    if (contractChoice) {
      setRowSelection((prev) => {
        const newValues = {
          ...prev,
          [contractChoice]: true,
        };
        return newValues;
      });
    }
  }, [contractChoice]);

  useEffect(() => {
    if (
      (verdict !== 'accept' || activeStep !== NoContractStep.Step1_Select) &&
      !(activeStep === NoContractStep.Step2_Resolution && verdict === 'reject-reject' && agreementType === RejectEnum.CREATE_NEW_SHIPPING_SERVICE)
    ) {
      setRowSelection({});
    }
  }, [verdict, activeStep, agreementType]);

  const table = useMaterialReactTable({
    enableSorting: false,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableEditing: rowIsEditable,
    editDisplayMode: 'table',
    enableRowSelection: isSelectable,
    enableMultiRowSelection: false,
    getRowId: (originalRow) => `${originalRow.existingShippingService.contractFlowId}`,
    displayColumnDefOptions: {
      'mrt-row-select': {
        enableHiding: true,
        Header: '',
      },
    },
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    data,
    columns,
  });

  return <CustomTable key={name} table={table} />;
};
