import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Button from 'src/presentations/components/atom/button';
import Mail from 'src/assets/images/icons/mail.svg';
import { useGetFileUrl } from 'src/use-cases/invoice/useInvoices';
import { beautifyEnum } from 'src/utils/utility';
import { TransportMedium } from 'src/presentations/components/molecules/card/types';
import TransportMediumIcon from 'src/presentations/components/molecules/card/TransportMediumIcon';
import { InvoiceUploadSignedUrlRequestDto } from 'src/generated/services/TFinancialApi';
import { useContactSelector } from 'src/presentations/components/molecules/contact/ContactSelector';
import { INoContractHeaderProps } from './types';
import { useNoRateCardSelector } from './Selector';

export const NoContractHeader: FC<INoContractHeaderProps> = ({ invoice }) => {
  const [searchParams] = useSearchParams();
  const { modality, serviceProvider, setModality, setServiceProvider } = useNoRateCardSelector();
  const { setIsShowContact, setInvoice } = useContactSelector();
  const serviceParam = searchParams.get('provider');
  const modalityParam = searchParams.get('modality');

  useEffect(() => {
    if (modalityParam) {
      setModality(modalityParam as InvoiceUploadSignedUrlRequestDto['modality']);
    }
    if (serviceParam) {
      setServiceProvider(serviceParam);
    }
  }, [modalityParam, serviceParam, setModality, setServiceProvider]);

  const { refetch } = useGetFileUrl(invoice?.id);

  const onShowOriginalInvoice = async () => {
    const result = await refetch();
    if (result?.data) {
      window.open(result?.data, '_blank');
    }
  };
  const onClickContact = () => {
    const invoiceContact = {
      modality,
      provider: serviceProvider,
      invoiceNumber: invoice?.invoiceNumber,
      id: invoice?.id,
      // mblHbl: invoice?.mblHbl,
      mblHbl: '',
      status: invoice?.status,
    };
    setIsShowContact(true);
    setInvoice(invoiceContact);
  };

  return (
    <>
      <div className='flex items-center gap-10 mt-4'>
        <div className='flex items-center justify-center'>
          <TransportMediumIcon medium={modality as TransportMedium} iconColor='#9F9F9F' />
        </div>
        <h2 className='text-20 font-bold text-blue_grey-80 max-lg:text-18'>{beautifyEnum(serviceProvider)?.toUpperCase()}</h2>
        <div className='w-4 h-4 rounded-full bg-[#D9D9D9]' />
        <div className='uppercase text-neutral-60 text-16'>{modality}</div>
      </div>
      <div className='flex place-items-center justify-between'>
        <div>
          <div className='pt-12'>
            <h3 className='text-24 font-bold'>No Matching Rate Card Found</h3>
          </div>
          <div className='flex items-center gap-12 text-14 leading-18 text-blue_grey-80'>
            <div>
              Invoice No. <strong className='text-primary'>{invoice?.invoiceNumber}</strong>
            </div>
            <div className='w-1 h-14 bg-neutral-20' />
            <div>
              {/* get it from get shipment API */}
              {/* <strong className='text-primary'>{invoice?.shipmentNumber}</strong> */}
            </div>
          </div>
        </div>
        <div className='flex gap-16'>
          <Button variant='neutral_outline' onClick={onClickContact}>
            <div className='flex gap-8'>
              Contact Tetrixx Admin <img src={Mail} alt='send mail' />
            </div>
          </Button>
          <Button variant='primary_outline' onClick={onShowOriginalInvoice}>
            View Original Invoice
          </Button>
        </div>
      </div>
    </>
  );
};
