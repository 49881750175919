import { ReactNode, createContext, useMemo, useState } from 'react';

export interface IToastResultSelectorProps {
  children: ReactNode;
}

export interface IToastResultSelectorData {
  isShowResult: boolean;
  currentInvoiceId: string;
  workflowStatus: string;
  setIsShowResult(value: boolean): void;
  setCurrentInvoiceId(value: string): void;
  setWorkflowStatus(value: string): void;
  showToastResult(invoiceId: string, workflow: string): void;
  hideToastResult(): void;
}

export const ToastResultSelectorContext = createContext<IToastResultSelectorData>(null);

export const ToastResultSelector = ({ children }: IToastResultSelectorProps) => {
  const [isShowResult, setIsShowResult] = useState<boolean>(false);
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string>(null);
  const [workflowStatus, setWorkflowStatus] = useState<string>('');

  const showToastResult = (invoiceId: string, workflow: string) => {
    setIsShowResult(true);
    setCurrentInvoiceId(invoiceId);
    setWorkflowStatus(workflow);
  };

  const hideToastResult = () => {
    setIsShowResult(false);
    setCurrentInvoiceId(null);
    setWorkflowStatus('');
  };

  const value = useMemo(
    () => ({
      isShowResult,
      setIsShowResult,
      currentInvoiceId,
      setCurrentInvoiceId,
      workflowStatus,
      setWorkflowStatus,
      showToastResult,
      hideToastResult,
    }),
    [isShowResult, setIsShowResult, currentInvoiceId, setCurrentInvoiceId, workflowStatus, setWorkflowStatus]
  );

  return <ToastResultSelectorContext.Provider value={value}>{children}</ToastResultSelectorContext.Provider>;
};
