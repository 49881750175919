import { Link } from 'react-router-dom';
import IconTick from 'src/assets/images/icons/tick-circle-white.svg';
import { useEffect, useRef, useState } from 'react';
import { DeviationWorkflowAction } from 'src/infra/types/deviation';
import { useToastResult } from './useToastResult';

export interface IToastResultProps {}

export const ToastResult: React.FC<IToastResultProps> = () => {
  const { isShowResult, currentInvoiceId, hideToastResult, workflowStatus } = useToastResult();
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isShowResult && currentInvoiceId) {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(true);
        timeoutRef.current = setTimeout(() => {
          setIsVisible(false);
          setTimeout(() => hideToastResult(), 500);
        }, 15000);
      }, 500);
    } else {
      setIsVisible(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isShowResult, currentInvoiceId, hideToastResult]);

  if (!isShowResult || !currentInvoiceId) {
    return null;
  }

  const getInvoiceStatus = () => {
    let status = 'New Status';

    if (currentInvoiceId) {
      if (workflowStatus === DeviationWorkflowAction.READY_FOR_PAYMENT) {
        status = 'Ready for Payment';
      } else if (workflowStatus === DeviationWorkflowAction.SEND_TO_OPERATION) {
        status = 'Ready for Payment';
      } else if (workflowStatus === DeviationWorkflowAction.REJECT_INVOICE) {
        status = 'Rejected';
      }
    }

    return status;
  };

  const toastStyles = {
    enter: {
      opacity: 0,
      transform: 'translateY(100%)',
      transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
    },
    enterActive: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    exit: {
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'opacity 0.5s ease-in, transform 0.5s ease-in',
    },
    exitActive: {
      opacity: 0,
      transform: 'translateY(100%)',
    },
  };

  const combinedStyles = isVisible ? { ...toastStyles.enter, ...toastStyles.enterActive } : { ...toastStyles.exit, ...toastStyles.exitActive };

  return (
    <div className='fixed bottom-48 left-48 z-[990] flex' style={combinedStyles}>
      <div className='py-14 pl-20 pr-24 rounded-8 bg-[#202020]'>
        <div className='flex items-center gap-12'>
          <div className='w-24'>
            <img src={IconTick} alt='' className='w-full' />
          </div>
          <div className='flex-1'>
            <h3 className='text-white font-bold text-14 mb-2'>Invoice has been marked as &quot;{getInvoiceStatus()}&quot;</h3>
            <p className='text-12 text-neutral-50'>It is now available in Report & Analytics</p>
          </div>
        </div>
        <div className='mt-12 ml-36'>
          <Link
            to={`/action-details/${currentInvoiceId}`}
            className='text-primary text-12'
            onClick={() => {
              hideToastResult();
            }}
          >
            See Invoice
          </Link>
        </div>
      </div>
    </div>
  );
};
