import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { InvoiceDto, InvoiceUploadSignedUrlRequestDto } from 'src/generated/services/TFinancialApi';
import { useSimpleInvoiceDetail } from 'src/use-cases/invoice/useInvoices';

export interface INoRateCardSelectorProps {
  children: ReactNode;
}

export interface INoRateCardSelectorData {
  isShowModal: boolean;
  selected: InvoiceDto;
  modality: string;
  serviceProvider: string;
  showNoRateCard(value: InvoiceDto): void;
  loadInvoiceAndShowNoRateCard(value: string): void;
  closeNoRateCard(): void;
  setServiceProvider(value: string): void;
  setModality(value: InvoiceUploadSignedUrlRequestDto['modality']): void;
}

export const NoRateCardSelectorContext = createContext<INoRateCardSelectorData>(null);

export const NoRateCardSelector = ({ children }: INoRateCardSelectorProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<InvoiceDto>(null);
  const [selectedId, setSelectedId] = useState<string>(null);
  const [serviceProvider, setServiceProvider] = useState<string>('');
  const [modality, setModality] = useState<InvoiceUploadSignedUrlRequestDto['modality']>(null);

  // use get shipment instead of invoice detail
  const { data } = useSimpleInvoiceDetail(selectedId);

  useEffect(() => {
    if (data) {
      setSelected({
        ...data,
      } as InvoiceDto);
    }
  }, [data]);

  const showNoRateCard = useCallback((item: InvoiceDto) => {
    setSelected(item);
    setIsShowModal(true);
  }, []);

  const loadInvoiceAndShowNoRateCard = useCallback((itemId: string) => {
    setSelectedId(itemId);
    setIsShowModal(true);
  }, []);

  const closeNoRateCard = useCallback(() => {
    setSelected(null);
    setSelectedId(null);
    setIsShowModal(false);
  }, []);

  const value = useMemo(
    () => ({
      isShowModal,
      selected,
      serviceProvider,
      modality,
      setModality,
      setServiceProvider,
      loadInvoiceAndShowNoRateCard,
      showNoRateCard,
      closeNoRateCard,
    }),
    [isShowModal, selected, modality, serviceProvider, showNoRateCard, loadInvoiceAndShowNoRateCard, closeNoRateCard, setModality, setServiceProvider]
  );

  return <NoRateCardSelectorContext.Provider value={value}>{children}</NoRateCardSelectorContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export function useNoRateCardSelector() {
  const context = useContext(NoRateCardSelectorContext);

  if (!context) {
    throw new Error(`No Contract selector compound components cannot be rendered outside the No Contract Selector component`);
  }
  return context;
}
